'use client';
import clsx from 'clsx';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import FourOFour from '@/assets/images/404.svg';
import { Button } from '@/components/button/Button.component';
import { ImageType } from '@/types/Image';

import styles from './NotFoundContents.module.scss';

type NotFoundContentsProps = {
    strings: {
        title: string;
        description: string;
        return_home: string;
    };
    image: ImageType;
};

export const NotFoundContents = ({ strings, image }: NotFoundContentsProps) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const onPageLoad = () => {
            setLoaded(true);
        };
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <section className={styles.container}>
            <div className={clsx(styles.badgeWrapper, { [styles.loaded]: loaded })}>
                <Image
                    src={image.src}
                    className={styles.badge}
                    alt=""
                    width={image.width}
                    height={image.height}
                />
                <FourOFour className={styles.number} />
            </div>
            <div className={clsx(styles.textblock, { [styles.loaded]: loaded })}>
                <h2>{strings.title}</h2>
                <p>{strings.description}</p>
                <Button href="/" kind="secondary" extraClass={styles.joinBtn}>
                    {strings.return_home}
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" />
                    </svg>
                </Button>
            </div>
        </section>
    );
};
